import Mock from "../mock";

const database = {
  information: {
    name: "Baki Yüksel",
    aboutContent: "I am just another developer.",
    age: 28,
    phone: "",
    nationality: "Nederlandse",
    language: "English, Dutch, Turkish",
    email: "bakiyuksel@live.nl",
    address: "",
    freelanceStatus: "Available",
    socialLinks: {
      facebook: "https://www.facebook.com/baki.yuksel.12935",
      twitter: "https://twitter.com/BakiYuksel_",
      pinterest: "",
      behance: "",
      linkedin: "https://www.linkedin.com/in/bakiyuksel/",
      dribbble: "",
      github: "https://github.com/bakiyuksel",
    },
    brandImage: "/img/brand-image.jpeg",
    aboutImage: "/img/brand-image.jpeg",
    aboutImageLg: "/img/brand-image.jpg",
    cvfile: "/files/empty.pdf",
  },
  services: [
    {
      title: "Web Design",
      icon: "brush-alt",
      details: "",
    },
    {
      title: "Web Development",
      icon: "code",
      details: "",
    },
    {
      title: "Application Development",
      icon: "laptop",
      details: "",
    },
    {
      title: "Graphic Design",
      icon: "brush",
      details: "",
    },
  ],
  reviews: [
    {
      id: 1,
      content:
        "Baki joined us with a team of interns and immediately took a role of internal technical lead, creating a base for others, less experienced students, to join. During the 10 months internship project we saw Baki growing as a great team player - stable, supportive and knowledgeable. He clearly contributed to the project both technically and as a mentor. Baki has friendly and calm approach that stabilizing the team, and quite sharp eye to spot solutions in unexpected situations. We had great experience with Baki during the internship project, he is welcome back after graduation!",
      author: {
        name: "Jelena Gordijenko",
        designation: "Agile and Holacracy coach, Voiceworks",
      },
    },
    {
      id: 2,
      content:
        "Baki interned with us at Voiceworks for 9 months and was one of the most valuable resources in the team. He had more experience with development than the others in the group and thus, he was always an advantage to the team. He mentored others technically. I have seen his team mates gaining confidence with development, under his guidance. He has proved to be a good team-player, even though all his previous assignments were individual tasks. That also highlights the fact that he can adapt well to the working situations in any place. I was his Scrum Master and in the tenure of this internship, I saw him grow as an agile developer. He has a better understanding now of the pros and cons of working in Agile and waterfall methods (having worked in both). He has clarity of thoughts, often have observed him offering solutions to the team. I think that he will be a very good addition to any team or any company.",
      author: {
        name: "Suchandra Sen",
        designation: "Software QA engineer, voiceworks",
      },
    },
  ],
  skills: [
    {
      title: "Javascript",
      value: 50,
      image: "/img/skills/js.png"
    },
    {
      title: "jQuery",
      value: 50,
      image: "/img/skills/jquery.png"
    },
    {
      title: "ReactJS",
      value: 70,
      image: "/img/skills/react.png"
    },
    {
      title: "VueJS",
      value: 65,
      image: "/img/skills/vuejs.png"
    },
    {
      title: "PHP",
      value: 85,
      image: "/img/skills/php.png"
    },
    {
      title: "C#",
      value: 60,
      image: "/img/skills/csharp.png"
    },
    {
      title: "MySQL",
      value: 80,
      image: "/img/skills/mysql.png"
    },
    {
      title: "Laravel",
      value: 55,
      image: "/img/skills/laravel.png"
    },
  ],
  learning: [
    {
      title: "AWS",
      image: "/img/skills/aws.png"
    },
    {
      title: "Azure",
      image: "/img/skills/azure.png",
    }
  ],
  portfolios: [
    /*
    {
      id: 1,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 2,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    */
    {
      id: 1,
      title: "Allinq Holovision",
      subtitle:
        "Hololens data dashboard made in React. Helped building hololens app in unity. Work: Design website and Unity App, Making Components for the dashboard.",
      imageUrl: "/img/allinq/dashboard.png",
      url: "",
    },
    {
      id: 2,
      title: "Docworks",
      subtitle:
        "Documentation platform made with in php. Work: Front-end design, Back-end support.",
      imageUrl: "/img/vw/login.png",
      largeImageUrl: ["/img/vw/login.png"],
      url: "",
    },
    {
      id: 3,
      title: "Hybridnomads",
      subtitle:
        "Wordpress webshop for jewelry. Work: Setup Wordpress, Theme design, Woocommerce setup.",
      imageUrl: "/img/hybridnomads/home.png",
      largeImageUrl: ["/img/hybridnomads/home.png"],
      url: "https://www.hybridnomads.com",
    },
    {
      id: 4,
      title: "CNRDETAILING",
      subtitle:
        "Wordpress website for a detailing company. Work: Host setup, Email setup, Wordpress setup(plugins and settings), Graphics(Logo, Business cards).",
      imageUrl: "/img/cnrdetailing/banner.png",
      largeImageUrl: ["/img/cnrdetailing/banner.png"],
      url: "https://cnr-detailing.nl/",
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 94,
        year: "2020 - 2020",
        position: "Minor",
        company: "Wehkamp",
        details: "Researched and developed a poc for a new product.",
      },
      {
        id: 95,
        year: "2019 - 2019",
        position: "Intern",
        company: "Allinq",
        details:
          "Researched how to implement hololens in the work environment, made an application and dashboard.",
      },
      {
        id: 96,
        year: "2018 - 2019",
        position: "Intern",
        company: "Voiceworks",
        details: "Research the software architecture to make a documentation platform, developed a documentation platform.",
      },
      {
        id: 97,
        year: "2017 - 2018",
        position: "Apple IT-Support",
        company: "Hulpstation",
        details:
          "Fixing Apple computer problems from distance and at location.",
      },
      {
        id: 98,
        year: "2016 - 2017",
        position: "Intern/Worker",
        company: "Aareon Emmen",
        details:
          "Barcode scanner: Research what the other intern used to make the software, made it functional and added QOL features. Label printer: renewed code to make the software work again in c#. DB management system made in laravel",
      },
      {
        id: 99,
        year: "2015 - 2016",
        position: "Intern",
        company: "Diamond Agency/TPC Media",
        details: "Helped build the website for startup and maintained customer websites.",
      },
    ],
    educationExperience: [
      {
        id: 1,
        year: "",
        graduation: "HBO",
        university: "Windesheim Flevoland",
        details: "Minor: E-commerce",
      },
      {
        id: 97,
        year: "2012 - 2017",
        graduation: "MBO",
        university: "Drenthe College Emmen",
        details: "Finished",
      },
      {
        id: 98,
        year: "2008 - 2010",
        graduation: "VMBO",
        university: "Carmel College Emmen",
        details: "Finished.",
      },
      {
        id: 99,
        year: "2006 - 2008",
        graduation: "VMBO",
        university: "Esdal College Emmen",
        details: "",
      },
    ],
  },
  blogs: [],
  contactInfo: {
    phoneNumbers: [],
    emailAddress: ["bakiyuksel@live.nl"],
    address: "Almere",
  },
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/learning").reply((config) => {
  const response = database.learning;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
