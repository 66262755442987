import React from "react";
import Particles from "react-tsparticles";

function Particle() {
  const paramConfig = {
    particles: {
      number: {
        value: 120,
        density: {
          enable: false,
        },
      },
      color: {
        value: "#f95959",
      },
      opacity: {
        value: 0.7,
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 2,
          size_min: 0.5,
        },
      },
      line_linked: {
        enable: true,
      },
      move: {
        enable: true,
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out",
      },
    },
  };

  return <Particles className="mi-home-particle" />;
}

export default Particle;
