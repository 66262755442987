import React, { useState } from "react";

function Portfolio(props) {
  const [toggler, setToggler] = useState(false);
  const { title, subtitle, imageUrl } = props.content;

  const handleToggler = (value) => {
    setToggler(value);
  };

  return (
    <div className="mi-portfolio mi-portfolio-visible">
      <div className="image">
        <img src={imageUrl} alt={title} />
      </div>
      <h5>{title}</h5>
      <h6>{subtitle}</h6>
    </div>
  );
}

export default Portfolio;
